import React from "react";
import styles from "./LoadingSpinner.module.scss";

export default function LoadingSpinner() {
  return (
    <div className={styles.SpinnerContainer}>
      <div className={styles.LoadingSpinner}>
      </div>
    </div>
  );
}