import React, { useState } from 'react'

import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import { useNavigate } from 'react-router-dom'

import { paths } from 'assets/constants/navigation'
import privacyDoc from 'assets/docs/AvisoPrivacidad.pdf'
import userManual from 'assets/docs/GuiaUsuario.pdf'
import headerLiana from 'assets/images/Liana-Vycross-Header.png'
import downloadIcon from 'assets/images/download-icon.svg'
import juvedermLogo from 'assets/images/logo-juvederm-2.svg'
import vycrossLogo from 'assets/images/logo-juvederm-vycross-header.svg'
import juvedermBigIcon from 'assets/images/juvederm-icon-xl.svg'
import juvedermCollaborationIcon from 'assets/images/juvederm-collaboration-icon.svg'
import juvedermConnectionIcon from 'assets/images/juvederm-connection-icon.svg'
import juvedermRelationIcon from 'assets/images/juvederm-relation-icon.svg'
import juvedermIconBlue from 'assets/images/juvederm-icon-blue.svg'
import juvedermIconDarkgreen from 'assets/images/juvederm-icon-darkgreen.svg'
import juvedermIconDarkviolet from 'assets/images/juvederm-icon-darkviolet.svg'
import juvedermIconViolet from 'assets/images/juvederm-icon-violet.svg'
import juvedermIconWhite from 'assets/images/juvederm-icon-white.svg'
import playIcon from 'assets/images/play-icon.svg'
import referenceIcon from 'assets/images/reference-icon.svg'
import juvedermWhite from 'assets/images/juvederm-white.png'
import graphIcon from 'assets/images/graph-icon.png'
import moleculesIcon from 'assets/images/molecules-icon.png'
import hourglassIcon from 'assets/images/hourglass-icon.png'
import injectionIcon from 'assets/images/injection-icon.png'
import Header from 'components/UI/Header/Header'
import IconLink from 'components/UI/IconLink/IconLink'
import Modal from 'components/UI/Modal/Modal'
import styles from './JuvedermVycrossInfo.module.scss'


const Property = (props) => {
    const { className, icon, title, firstLine, secondLine } = props

    return (
        <div className={`${styles.Property} ${className}`}>
            <img src={icon} alt='Juvederm icon' />
            <div className={styles.PropertyTitle}>
                <span>{title}</span>
            </div>
            <span>{firstLine}</span>
            <span>{secondLine}</span>
        </div>
    )
}

const VideoCard = (props) => {
    const { icon, title, url = '#' } = props

    return (
        <div className={styles.VideoCard}>
            <img src={icon} alt='Juvederm icon' />
            <h3 className={styles.CardTitle}>
                {title}
            </h3>
            <div className={styles.VideoLink}>
                <img src={playIcon} alt='Play icon' />
                <a href={url} target='_blank' rel='noreferrer'>Ver Vídeo</a>
            </div>
        </div>
    )
}

const BenefitCard = (props) => {
    const { index, title, text } = props

    return (
        <div className={`${styles.BenefitCard} ${(index % 2) === 0 ? styles.Even : styles.Odd}`}>
            <div className={styles.BenefitHeader}>
                <span className={styles.BenefitIndex}>{`${index}`.padStart(2, '0')}</span>
                <h2 className={styles.BenefitTitle}>
                    {title}
                </h2>
            </div>
            <p className={styles.BenefitDescription}>
                {text}
            </p>
        </div>
    )
}


const JuvedermVycrossInfo = () => {
    const [isModalVisible, setModalVisible] = useState(false)
    const navigate = useNavigate()

    return (
        <>
            <Helmet>
                <title>Juvéderm Vycross - Info</title>
            </Helmet>
            <Header
                inverted
                logo={vycrossLogo}
                logoMobile={vycrossLogo}
                color='grape'
                className={styles.Header}
            />
            <main className={styles.Content}>
                <section className={styles.section1}>
                    <h1>
                        <span>Tecnología</span>
                        <span>innovadora</span>
                    </h1>
                    <img src={headerLiana} alt='Liana' />
                </section>
                <section className={styles.section2}>
                    <div className={styles.Rectangle}>
                        <h1>La fórmula de</h1>
                        <img src={juvedermWhite} alt="Juvederm White" />
                        <div className={styles.Text}>
                        I + D + C
                        </div>
                    </div>
                   <div className={styles.DiscoverText}>
                   Descubre la <span>ciencia</span> que hay detrás
                   </div>
                    </section>
                    <section className={styles.section3}>
                    <h1>Conoce la fórmula <span>I+D+C</span></h1>
                    <div className={styles.MainGrid}>
                        <div className={styles.ChildGrid}>
                            <div className={styles.ChildHeader}>
                            <h2>Individualidad<sup>1</sup></h2>
                            <span>+</span>
                            </div>
                            <img src={graphIcon} alt="Icon" />
                            <div className={styles.Text}>
                            <b>Gama completa</b><sup>1</sup> con progresiva elasticidad y cohesividad, que permite un <b>abordaje global del rostro</b> adaptándose a las distintas necesidades de los pacientes.
                            </div>
                            <img src={moleculesIcon} alt="Icon" />
                            <div className={styles.Text}>
                            Con <b>resultados naturales</b><sup>1,4</sup> por la alta integración en los tejidos.
                            </div>
                        </div>
                        <div className={styles.ChildGrid}>
                            <div className={styles.ChildHeader}>
                            <h2>Duración<sup>2,3</sup></h2>
                            <span>+</span>
                            </div>
                            <img src={hourglassIcon} alt="Icon" />
                            <div className={styles.Text}>
                            <b>Gama patentada de geles de alta reticulación</b> que limitan la entrada de agua y enzimas, <b>permitiendo una duración de hasta 24 meses</b>.
                            </div>
                        </div>
                        <div className={styles.ChildGrid}>
                            <div className={styles.ChildHeader}>
                            <h2>Calidad<sup>5,6</sup></h2>
                            </div>
                            <img src={injectionIcon} alt="Icon" />
                            <div className={styles.Text}>
                             Que se percibe en la <b>suavidad</b> de los geles y la innovación* de la jeringa ergonómica, <b>resultando en una menor fuerza de extrusión</b>.
                            </div>
                        </div>
                     </div>
                     </section>
              <section className={styles.section4}>
                    {/* <div className={styles.PropertiesRow}>
                        <Property
                            className={styles.Connection}
                            icon={juvedermConnectionIcon}
                            title='Conexión'
                            firstLine='Pacientes'
                            secondLine='Personalización'
                        />
                        <img src={juvedermBigIcon} alt='Juvederm icon' />
                        <Property
                            className={styles.Relation}
                            icon={juvedermRelationIcon}
                            title='Relación'
                            firstLine={<>Profesional<br />sanitario</>}
                            secondLine='Habilidades'
                        />
                    </div>
                    <div className={styles.PropertiesRow}>
                        <Property
                            className={styles.Collaboration}
                            icon={juvedermCollaborationIcon}
                            title='Colaboración'
                            firstLine={<>Juvéderm<sup>®</sup></>}
                            secondLine={<>Colección<br />completa</>}
                        />
                    </div> */}
                    <h2>
                        Descubre en vídeo las características <br />
                        de la gama Juvéderm Vycross
                    </h2>
                    <div className={styles.SmallSeparator} />
                    <div className={styles.VideoCardsRow}>
                      
                        <VideoCard
                            icon={juvedermIconViolet}
                            title={<>Capacidad<br />de Lifting</>}
                            url='https://youtu.be/6siDZaCCZvU'
                        />
                            <VideoCard
                            icon={juvedermIconDarkviolet}
                            title='Cohesividad'
                            url='https://youtu.be/OCdtvbeygjI'
                        />
                    </div>
                    <div className={styles.VideoCardsRow}>
                    
                        <VideoCard
                            icon={juvedermIconDarkgreen}
                            title='Elasticidad'
                            url='https://youtu.be/CEhXXc5O3vo'
                        />
                        <VideoCard
                            icon={juvedermIconWhite}
                            title={<>Extensibilidad<br />de Gel</>}
                            url='https://youtu.be/A_xuCURJafU'
                        />
                    </div>
                </section>
                {/* <section className={styles.section5}>
                    <h1>
                        El ácido hialurónico se debe adaptar al movimiento
                        <br />
                        de cada paciente y en todas las circunstancias
                    </h1>
                    <div className={styles.JuvedermSeparator}>
                        <div />
                        <img src={juvedermIconWhite} alt='Juvederm icon' />
                        <div />
                    </div>
                    <p>
                        Si bien la zona y la profundidad de la inyección son importantes, las cualidades reológicas también
                        <br />
                        adquieren una gran importancia, principalmente en la preservación de la expresividad. <sup>1</sup> 
                    </p>
                    <div className={styles.Benefits}>
                        <div className={styles.BenefitsRow}>
                            <BenefitCard
                                index={1}
                                title='Adaptabilidad/ Personalización²'
                                text={(
                                    <>
                                        Tienen distintas concentraciones de AH, a la vez que una progresiva cohesión y elasticidad lo que permite que cada gel se adapte a un resultado estético concreto.

                                    </>
                                )}
                            />
                            <BenefitCard
                                index={2}
                                title='Duración³¯⁴'
                                text={(
                                    <>
                                        Gama patentada de cadenas de ácido hialurónico de alto y bajo peso molecular, obteniendo geles muy reticulados, que permiten una duración de hasta 24 meses
. 
                                    </>
                                )}
                            />
                          
                        </div>
                        <div className={styles.BenefitsRow}>
                        <BenefitCard
                                index={3}
                                title='Naturalidad'
                                text={(
                                    <>
                                        Captación mínima de agua²: menor hinchazón del gel<br></br>
Alta integración en los tejidos a la semana de tratamiento. 
                                    </>
                                )}
                            />
                            <BenefitCard
                                index={4}
                                title='Comodidad y control⁵'
                                text={(
                                    <>
                                        Presentan una jeringa ergonómica y textura de gel suave que requiere menor fuerza de extrusión. 
                                    </>
                                )}
                            />
                           
                         
                        </div>
                    </div>
                    <div className={styles.BackBtn}>
                        <IconLink
                            title='Volver'
                            icon={faArrowLeft}
                            className={styles.BackLink}
                            onClick={() => navigate(paths.HOME)}
                        />
                    </div>  
                    <img
                        src={referenceIcon}
                        alt='Reference icon'
                        className={styles.ReferenceIcon} 
                        onClick={() => setModalVisible(true)}
                    />
                </section> */}
                <section className={styles.finalSection}>
                    <div className={styles.BackBtn}>
                        <IconLink
                            title='Volver'
                            icon={faArrowLeft}
                            className={styles.BackLink}
                            onClick={() => navigate(paths.HOME)}
                        />
                    </div>  
                </section>
                <img
                    src={referenceIcon}
                    alt='Reference icon'
                    className={styles.ReferenceIcon} 
                    onClick={() => setModalVisible(true)}
                />
                <footer>
                    <div className={styles.FooterRow}>
                        <a href={userManual} target='_blank' rel='noreferrer'>
                            <span>Descargar Guía de Usuario</span>
                            <img src={downloadIcon} alt='Download icon' />
                        </a>
                        <div>
                            <p>El contenido de esta web está dirigido exclusivamente a profesional sanitario</p>
                        </div>
                        <div className={styles.PrivacyAndTerms}>
                            <Link
                                to={paths.PRIVACY_NOTICE}
                                target='_blank'
                                rel='noreferrer'
                                className={styles.Terms}
                            >
                                <p>Aviso de privacidad</p>
                            </Link>
                            <p>&nbsp;|&nbsp;</p>
                            <a
                                href='https://www.allerganaesthetics.es/terminos-y-condiciones'
                                target='_blank'
                                rel='noreferrer'
                                className={styles.Terms}
                            >
                                <p>Términos y condiciones</p>
                            </a>
                        </div>
                    </div>
                    <div className={styles.FooterRow}>
                        <img src={juvedermLogo} alt='Juvéderm icon' />
                        <div>
                        <p>ES-JUV-220030 &nbsp;/&nbsp; Fecha de elaboración: Enero 2023</p>
                        </div>
                        <div>
                        <p>©AbbVie S.L.U. 2022. Todos los derechos reservados</p>
                        </div>
                    </div>
                </footer>
            </main>
            {
                isModalVisible &&
                <Modal onClose={() => setModalVisible(false)} className={styles.ReferencesModal}>
                    <div className={styles.References}>
                        <div className={styles.Reference}>
                        * Mejora en el diseño de la jeringa de Juvéderm con respecto a los modelos anteriores.
                        </div>
                        <div className={styles.Reference}>
                        1. Pierre S, Liew S, Ben A. Basics of dermal filler rheology, Dermatol Surg, 2015;41 Suppl 1:S120-6.
                        </div>
                        <div className={styles.Reference}>
                        2. Tezel H, Fredrickson GH, The science of hyaluronic acid dermal fillers, Journal of Cosmetic and Laser Therapy, 2008; 10: 35-42.
                        </div>
                        <div className={styles.Reference}>
                        3. Eccleston D, Murphy DK, Juvéderm ® Volbella ® in the perioral area; a 12-month prospective, multicenter open-label study, Clinical, Cosmetic and Investi-gational Dermatology 2012:5; 167-172.
                        </div>
                        <div className={styles.Reference}>
                        4. Hee CK, Shumate GT, Narurkar V, Bernadrin A et al. Rheological Properties and In Vivo Perfomance Characteristics of Soft Tissue Fillers, Dermatol Surg 2015;41:S373-S381.
                        </div>
                        <div className={styles.Reference}>
                        5. Costello K, Dally N, Mallet C, Mandaroux B. In-Field Assessment of the Perception of Smoothness When Dispesing Different Filler Formulations Presentado en International Master Coruse on Aging Science (IMCAS), June 3-5, 2022, Paris, France.
                        </div>
                        <div className={styles.Reference}>
                        6. Kevin Costello, et al. A New Dermal Filler Syringe With Improved Ergonomic. Perfomance to Enhance End-User Experience, IMCAS 2022.
                        </div>
                        </div>
                </Modal>
            }
        </>
    )
}

export default JuvedermVycrossInfo
