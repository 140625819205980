import React from 'react'

//import ReactDOM from 'react-dom'
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'

import AuthProvider from 'providers/authProvider'
import TreatmentProvider from 'providers/treatmentProvider'
import App from './App'

import './index.scss'

const container = document.getElementById('root')
const root = createRoot(container);

//ReactDOM.render(
root.render (
    <Router>
        <React.StrictMode>
            <AuthProvider>
                <TreatmentProvider>
                    <HelmetProvider>
                        <App />
                    </HelmetProvider>
                </TreatmentProvider>
            </AuthProvider>
        </React.StrictMode>
    </Router>
)
