import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import styles from './IconLink.module.scss'

const IconLink = (props) => {
    const { title, icon, iconPosition, size, className, onClick } = props

    return (
        <div
            className={
                `${styles.IconLink} ${size === 'big' ? styles.Big : styles.Normal}${className ? ` ${className}` : ''}`
            }
            onClick={onClick}
        >
            {
                iconPosition !== 'right' &&
                <FontAwesomeIcon className={styles.Icon} icon={icon} />
            }
            <span className={styles.Title}>{title}</span>
            {
                iconPosition === 'right' &&
                <FontAwesomeIcon className={styles.Icon} icon={icon} />
            }
        </div>
    )
}

export default IconLink