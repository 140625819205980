import React, { useEffect } from 'react'

import { Helmet } from 'react-helmet-async'

import './PrivacyNotice.scss'

const PrivacyNotice = () => {
    useEffect(() => {
        if (!window.OneTrust.NoticeApi.Initialized) {
            return
        }

        window.OneTrust.NoticeApi.LoadNotices(
            ['https://privacyportal-cdn.onetrust.com/baa8d3f1-40eb-408e-9928-100470b8ea17/privacy-notices/55686698-7c90-4244-ad50-77cae5eaba40.json']
        )
    }, [])

    return (
        <>
            <Helmet>
                <title>Aviso de privacidad</title>
            </Helmet>
            <div id='otnotice-55686698-7c90-4244-ad50-77cae5eaba40' className='otnotice' />
        </>
    )
}

export default PrivacyNotice