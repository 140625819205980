import React, {useContext, useEffect, useState} from 'react'

import { Helmet } from 'react-helmet-async'
import { useNavigate } from 'react-router-dom'

import Menu from 'components/Menu/Menu'
import Header from 'components/UI/Header/Header'
import ChangeBtn from 'components/UI/ChangeBtn/ChangeBtn'
import Footer from 'components/Footer/Footer'
import styles from './Home.module.scss'
import Head from 'components/UI/Head/Head'
import LateralCard from 'components/UI/LateralCard/LateralCard'
import FaceSwitch from 'components/UI/FaceSwitch/FaceSwitch'
import Modal from 'components/UI/Modal/Modal'
import juvedermLogo2 from 'assets/images/logo-juvederm-2.svg'
import { paths } from 'assets/constants/navigation'
import vistabelLogo from 'assets/images/logo-vistabel.png'
import harmonycaLogo from 'assets/images/logo-harmonyca.png'
import scroll from 'assets/images/scroll.svg'
import plus from 'assets/images/plus-btn.svg'
import close from 'assets/images/close-menu.svg'
import table from 'assets/images/view-table.svg'
import table2 from 'assets/images/view-table2.svg'
import logoAllerganMobile from 'assets/images/juvederm-mobile-logo.svg'

import VycrossProductsTable from 'components/VycrossProductsTable/VycrossProductsTable'
import { TreatmentContext } from 'providers/treatmentProvider'
import { Cookies } from '../../components/Cookies/Cookies'


const Home = () => {
    const [store, setStore] = useContext(TreatmentContext)
    const [isInfoModalOpen, setInfoModalOpen] = useState(false)
    const [isMenuMobileVisible, setMenuMobileVisible] = useState(false)
    const [isOpenMenu, setIsOpenMenu] = useState(false)

    const navigate = useNavigate()

    useEffect(() => {
        setStore({...store, vistabel: false, harmonyca: false})
        if(document.cookie.indexOf("OptanonAlertBoxClosed") < 0) window.location.reload(false)
        
    },[])

    const scrollToButtom = () =>{
        const element = document.getElementById('footer');
        if (element) {
        // 👇 Will scroll smoothly to the top of the next section
        element.scrollIntoView({ behavior: 'smooth' });
        }
    }

    const toogleMenu = () =>{
        setIsOpenMenu(!isOpenMenu);
    }

    const viewTable1 = () =>{
        setIsOpenMenu(false);
        setStore({...store, modal2Open: true});

    }

    const viewTable2 = () =>{
        setIsOpenMenu(false);
        setStore({...store, modalOpen: true});

    }

    return (
        <>
            <Helmet>
                <title>Inicio - Juvederm</title>
            </Helmet>
            <div className={styles.Wrapper}>
                <Menu
                    className={`${styles.SideMenu}${isMenuMobileVisible ? ` ${styles.VisibleMobile}` : ''}`}
                    onCloseIconClick={() => setMenuMobileVisible(false)}
                />
                <main className={styles.Content}>
                    <Header title='CREA LO EXTRAORDINARIO' menuIcon onMenuIconClick={() => setMenuMobileVisible(true)} logoMobile={logoAllerganMobile} />
                    <div className={styles.Columns}>
                        <div className={styles.FirstColumn}>
                            <div className={styles.floatMenu}>
                                <img className={styles.plus} src={!isOpenMenu ? plus : close} alt='plus' onClick={() => toogleMenu()}/>
                                <div className={!isOpenMenu ? styles.tableN : styles.table} ><p>Ver Tabla Descriptiva</p> <img src={table} alt='plus' onClick={() => viewTable1()}/></div>
                                <div className={!isOpenMenu ? styles.tableN : styles.table}><p>Ver Tabla Comparativa</p><img src={table2} alt='plus' onClick={() => viewTable2()}/></div>
                            </div>
                            <Head/>
                            <img className={styles.scroll} src={scroll} alt='scroll' onClick={() => scrollToButtom()} />
                        </div>
                        <div className={styles.SecondColumn}>
                            <FaceSwitch />
                            <LateralCard className={styles.ThirdsInfo} color='light' />
                            <div className={styles.ChangeTo}>
                                <p>Cambia a</p>
                                <ChangeBtn
                                    className={styles.ChangeBtn}
                                    logo={vistabelLogo}
                                    onClick={() => {
                                        setStore({...store, vistabel: true})
                                        navigate(paths.VISTABEL)
                                    }}    
                                />
                            </div>

                            <div className={styles.ChangeTo}>
                                <p>Cambia a</p>
                                <ChangeBtn
                                    className={styles.ChangeBtn}
                                    logo={harmonycaLogo}
                                    onClick={() => {
                                        setStore({...store, harmonyca: true})
                                        navigate(paths.HARMONYCA)
                                    }}    
                                />
                            </div>
                        </div>
                    </div>
                </main>
            </div>
            <Footer logo={juvedermLogo2} className={styles.Footer} />
            {
                store.modalOpen &&
                <Modal onClose={() => setStore({...store, modalOpen: false})}>
                    <VycrossProductsTable
                        showInfoModal={() => {
                            setStore({...store, modalOpen: false})
                            setInfoModalOpen(true)
                        }}
                    />
                </Modal>
            }
            {
                store.modal2Open &&
                <Modal onClose={() => setStore({...store, modal2Open: false})}>
                    <LateralCard className={styles.ThirdsInfo} color='light' />
                </Modal>
            }
            {
                isInfoModalOpen &&
                <Modal
                    onClose={() => {
                        setInfoModalOpen(false)
                        setStore({...store, modalOpen: true})
                    }}
                    className={styles.ReferencesModal}>
                    <div>
                        <p>
                            AH, ácido hialurónico. La duración depende de la zona de tratamiento. Imágenes puramente 
                            ilustrativas. Representación de los datos no exhaustiva.11*Una fuerza de extrusión de 
                            &lt;20 N se considera baja cuando se inyecta a una velocidad fija de 50mm/min. La fuerza 
                            de extrusión del gel es una característica que describe su respuesta física a las fuerzas 
                            aplicadas con la inyección y no se deben traducir de forma individual al rendimiento 
                            clínico.11 **Las propiedades reológicas del gel son características para describir sus 
                            respuestas físicas a las fuerzas aplicadas in vitro y no se deben traducir de forma 
                            individual al rendimiento clínico.12,13 ‡ Los estudios que evaluaron la duración de 
                            Juvéderm® VOLITE usaron Juvéderm® VOLITE B (sin lidocaína).
                        </p>
                        <p>
                        1. Juvéderm® VOLITE DFU. / 2. Juvéderm® VOLBELLA with lidocaine DFU / 3. Juvéderm® VOLIFT with lidocaine DFU. / 4. Juvéderm® VOLUMA with lidocaine DFU. /5. Juvéderm® VOLUX DFU. 6.Niforos F, et. al. VYC-12 Injectable Gel Is Safe And Effective For Improvement Of Facial Skin Topography: A Prosp ective Study. Clin Cosmet Investig Dermatol. 2019 Oct 24;12:791-798./ 7.Niforos F, et al. A Prospective, Open-Label Study of Hyaluronic Acid-Based Fill er With Lidocaine (VYC-15L) Treatment for the Correction of Infraorbital Skin Depressions. Dermatol Surg. 2017 Oct;43(10):1271-1280./ 8.Dayan S, et al. Safety and Effectiveness of VYC-17.5L for Long-Term Correction of Nasol abial Folds. A esthet Surg J. 2020 Jun 15;40(7):767-777. / 9.Callan P, et al. Efficacy and safety of a hyaluronic acid filler in subjects treated for correction of midface volume deficiency: a 24 mon th study. Clin Cosmet Investig Dermatol. 2013 Mar 20;6:81-9./ 10.Ogilvie P, et al . VYC-25L Hyaluroni c Acid Injectable Gel Is Safe and Effective for Long-Term Restoration and Creation of Volume of the Lower Face. Aesthet Surg J. 2020 Aug 14;40(9):NP499-NP510. 11.Costello K, Dally N, Mallet C, Mandaroux B, A New Dermal Filler Syringe With Improved Ergonomic Performance to Enhance End-User Experience. IMCAS, June 3-5, 2022, Paris, France. 12.Costello K, Dally N, Mallet C, Mandaroux B, In-Field Assessment of the Perception of Smoothness When Dispensing Different Filler Formulations. IMCAS, June 3-5, 2022, Paris, France./ 13.de la Guardia C,. Rheologic and Physicochemical Characteristics of H yaluronic Acid Fillers: Overview and Relationship to Product Performance. Facial Plast Surg. 2022 Apr;38(2):116-123. /
</p>
                    </div>
                </Modal>
            }
        </>
    )
}

export default Home
