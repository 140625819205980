import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons'

import styles from './Modal.module.scss'


const Modal = (props) => {
    const { children, className, onClose } = props

    return (
        <div className={styles.Dimmer}>
            <div className={`${styles.Modal} ${className ? ` ${className}` : ''}`}>
                <FontAwesomeIcon
                    className={styles.CloseButton}
                    icon={faCircleXmark}
                    onClick={onClose}
                />
                {children}
            </div>
        </div>
    )
}

export default Modal
