import React from 'react'

import { Link } from 'react-router-dom'

import { paths } from 'assets/constants/navigation'
import userManual from 'assets/docs/GuiaUsuario.pdf'
import downloadIcon from 'assets/images/download-icon.svg'
import styles from './Footer.module.scss'


const Footer = (props) => {
    const { className, color, logo } = props
    return (
        
        <footer className={`${styles.Wrapper}${className ? ` ${className}` : ''}`} id="footer">
            <div className={`${styles.Footer} ${color === 'grey' ? styles.GreyBg : styles.VioletBg}`}>
                <a href={userManual} target='_blank' rel='noreferrer' className={styles.UserGuideAndTerms}>
                    <p>Descargar Guía de Usuario</p>
                    <img src={downloadIcon} alt='Download icon' />
                </a>
                <div className={styles.disclaimer}>
                    <p>El contenido de esta web está dirigido exclusivamente a profesional sanitario</p>
                    <p>Juvéderm cumple con la legislación vigente. Ver instrucciones de uso para información sobre los efectos secundarios y las contraindicaciones.</p>
                </div>
                
                <div className={styles.PrivacyAndTerms}>
                    <Link
                        to={paths.PRIVACY_NOTICE}
                        target='_blank'
                        rel='noreferrer'
                        className={styles.Terms}
                    >
                        <p>Aviso de privacidad</p>
                    </Link>
                    <p>&nbsp;|&nbsp;</p>
                    <a
                        href='https://www.allerganaesthetics.es/terminos-y-condiciones'
                        target='_blank'
                        rel='noreferrer'
                        className={styles.Terms}
                    >
                        <p>Términos y condiciones</p>
                    </a>
                    <p>&nbsp;|&nbsp;</p>
                    <a
                        href='#'
                        target='_self'
                        rel='noreferrer'
                        title="Overridden by OneTrust"
                        className={styles.Terms + ' ot-sdk-show-settings'}
                    >
                        <p>Configuración de cookies</p>
                    </a>
                </div>

                <div className={styles.PrivacyAndTermsMobile}>
                    <Link
                        to={paths.PRIVACY_NOTICE}
                        target='_blank'
                        rel='noreferrer'
                        className={styles.Terms}
                    >
                        <p>Aviso de privacidad</p>
                    </Link>
                    <a
                        href='https://www.allerganaesthetics.es/terminos-y-condiciones'
                        target='_blank'
                        rel='noreferrer'
                        className={styles.Terms}
                    >
                        <p>Términos y condiciones</p>
                    </a>
                    <a
                        href='#'
                        target='_self'
                        rel='noreferrer'
                        title="Overridden by OneTrust"
                        className={styles.Terms + ' ot-sdk-show-settings'}
                    >
                        <p>Configuración de cookies</p>
                    </a>
                </div>
            </div>
            <div className={styles.SecondFooter}>
            <p className={styles.disclaimerlogo}></p>
                {
                    logo
                        ? <img className={styles.Logo} src={logo} alt='Logo' />
                        
                        : <div />
                        
                }
               
                <div className={styles.mobileBlock}>
                    <p>ES-JUV-220030 &nbsp;/&nbsp; Fecha de elaboración: Enero 2023</p>
                    
                </div>
                <div className={styles.mobileBlock}>
                    <p>©AbbVie S.L.U. {new Date().getFullYear()}. Todos los derechos reservados</p>
                </div>
            </div>
        </footer>
    )
}

export default Footer
