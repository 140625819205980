import React, {useContext} from 'react';
import styles from './FaceSwitch.module.scss';
import {TreatmentContext} from 'providers/treatmentProvider';

const FaceSwitch = ({props}) => {
    const [store,setStore] = useContext(TreatmentContext);

    const faceChange = () => {
        setStore({...store, face: !store.face});
    }

    return(
        <div className={styles.TitleFaceSwitch}>
            <strong>Cara seleccionable:</strong>
            <input type='checkbox' className={styles.AppleFaceSwitch} defaultChecked={store.face} onChange={faceChange}/>
        </div>
    )
}

export default FaceSwitch