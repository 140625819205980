import React, {useContext, useEffect, useState} from 'react'
import { Helmet } from 'react-helmet-async'
import { useNavigate } from 'react-router-dom'
import { paths } from 'assets/constants/navigation'
import harmonycaLogo from 'assets/images/logo-harmonyca.png'
import harmonycaLogo2 from 'assets/images/logo-harmonyca-white.png'
import Header from 'components/UI/Header/HeaderHarmonyca'
import MainContent from 'components/UI/MainContent/MainContent'
import Footer from 'components/Footer/FooterHarmonyca'
import styles from './HarmonycaInfo.module.scss'
import { TreatmentContext } from 'providers/treatmentProvider'
import Modal from 'components/UI/Modal/Modal'
import subTitle from 'assets/images/harmonyca-info-subtitle.svg'
import innovation from 'assets/images/innovation.svg'
import doubleCheck from 'assets/images/double-check.svg'
import checkBook from 'assets/images/check-book.svg'

import lamp from 'assets/images/harmonyca-info-lamp.svg'
import lampSub from 'assets/images/harmonyca-info-lamp-sub.svg'
import reload from 'assets/images/harmonyca-info-reload.svg'
import reloadSub from 'assets/images/harmonyca-info-reload-sub.svg'
import check from 'assets/images/harmonyca-info-check.svg'
import checkSub from 'assets/images/harmonyca-info-check-sub.svg'
import back from 'assets/images/harmonyca-back.svg'
import IconLink from 'components/UI/IconLink/IconLink'
//import referenceIcon from 'assets/images/ref-harmonyca-info-white.svg'
import referenceIcon from 'assets/images/reference-icon.svg'

const HarmonycaInfo = () => {
    const [isModalVisible, setModalVisible] = useState(false)
    const [isTableModalVisible, setTableModalVisible] = useState(false)
    const navigate = useNavigate()
    const [store,setStore] = useContext(TreatmentContext)

    const openPDF = (pdf) => {
        const tempAnchor = document.createElement('a')
        tempAnchor.setAttribute('rel', 'noopener')
        tempAnchor.setAttribute('target', '_blank')
        tempAnchor.setAttribute('href', pdf)
        tempAnchor.style.display = 'none'
        document.body.appendChild(tempAnchor)
        tempAnchor.click()
        document.body.removeChild(tempAnchor)
    }
    
    useEffect(() => {
        setStore({...store, vistabel: true})
    },[])

    return (
        <>
            <Helmet>
                <title>Harmonyca</title>
            </Helmet>
            <Header
                className={styles.Header}
                logo={harmonycaLogo}
                logoMobile={harmonycaLogo}
                color='white'
                title='HArmonyCa ha sido diseñado para uso subcutáneo'
            />
            <MainContent className={styles.MainContent}>
                
                <div className={styles.DetailsWrapper}>
                    {/* <h2>El MÉTODO <img className={styles.logo} src={harmonycaLogo2}></img></h2> */}
                    <img className={styles.subTitle} src={subTitle}></img>
                    <div className={styles.grid}>
                        <div className={styles.ChildGrid2}>
                            <img className={styles.logoGrid} src={innovation}></img>
                            <h3>INNOVACIÓN*</h3>
                            {/* <img className={styles.logoSub} src={lampSub}></img> */}
                            <p>Gel híbrido inyectable que combina ácido hialurónico e hidroxiapatita cálcica en una jeringa <strong>“Ready to use” <sup>1</sup></strong></p>
                            <br></br>
                            <p className={styles.italic}>* Gel híbrido inyectable con 55,7% de CaHa suspendida en AH al 20mg/ml</p>
                        </div>

                        <div className={styles.ChildGrid}>
                            <img className={styles.logoGrid} src={doubleCheck}></img>
                            <h3>DOBLE EFECTO</h3>
                            {/* <img className={styles.logoSub} src={reloadSub}></img> */}
                            <p>Aporta hidratación<sup>4</sup> y densidad<sup>3</sup> de forma inmediata<sup>3</sup> gracias al ácido hialurónico y trata la laxitud y flacidez<sup>2,5,6</sup> a largo plazo<sup>2,5</sup> gracias a la hidroxiapatita cálcica.</p>
                        </div>

                        <div className={styles.ChildGrid2}>
                            <img className={styles.logoGrid} src={checkBook}></img>
                            <h3>BENEFICIO</h3>
                            {/* <img className={styles.logoSub} src={checkSub}></img> */}
                                                        <p>Mejora la arquitectura de la piel<sup>1</sup> de forma sostenida en el tiempo<sup>7</sup>.</p>
                        </div>
                    </div>
                    {/* <IconLink
                        title='Volver'
                        icon={back}
                        className={styles.BackLink}
                        onClick={() => navigate(paths.HARMONYCA)}
                    /> */}
                    <div className={styles.InfoIcon} >
                        <img
                            src={referenceIcon} alt='Botón de información'
                            onClick={() => setModalVisible(true)}
                        />  
                    </div>
                    <a onClick={() => navigate(paths.HARMONYCA)}><img className={styles.back} src={back}></img></a>
                    
                </div>
                
            </MainContent>
            <Footer color={'orange'} logo={harmonycaLogo2}/>
            {
                isModalVisible &&
                <Modal onClose={() => setModalVisible(false)} className={styles.ReferencesModal}>
                    <div>
                        <p>
                            Se recomienda consultar la Ficha Técnica/Instrucciones de uso de cada producto 
                            antes de iniciar un tratamiento.
                        </p>
                        <p>
                        1. Evaluating the Effects of Injected Calcium Hydroxylapatite on Changes in human Skin Elastin (v1.0) 
                        2. Biostimulators and their mechanisms of action (v1.0) 
                        3. Biological Response of the Combination Filler, HArmonyCa™, in a Rodent Model (v1.1) 
                        4. M032 V01 HarmonyCa Lidocaine CE with needle IFU DRAFT (v1.0) 
                        5. Expert Consensus on Injection Technique and Area-Specific Recommendations for the Hyaluronic Acid (v1.0) 
                        6. Reshaping the Lower Face Using Injectable Fillers (v1.0) 
                        7. Rheological Properties and In Vivo Performance Characteristics of Soft Tissue Fillers (v1.0)
                        </p>
                    </div>
                </Modal>
            }
        </>
    )
}

export default HarmonycaInfo