const getCookie = (cookieName) => {
    const cookieString = `${cookieName}=`
    const decodedCookies = decodeURIComponent(document.cookie)
    const cookies = decodedCookies.split(';')

    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim()

        if (cookie.startsWith(cookieString)) {
            return cookie.slice(cookieString.length)
        }
    }

    return ''
}

export { getCookie }