import React from 'react'

import styles from './JuvedermIconItem.module.scss'


const JuvedermIconItem = (props) => {
    const { icon, title, className, size } = props

    return (
        <div
            className={
                `${styles.JuvedermIconItem} ${size === 'small' ? styles.Small : styles.Normal} ${className ? ` ${className}` : ''}`
            }
        >
            <img src={icon} />
            <span>{title}</span>
        </div>
    )
}

export default JuvedermIconItem