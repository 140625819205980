import React, {Suspense, useContext} from 'react';
import { Canvas, useThree } from '@react-three/fiber';
import { OrbitControls, useContextBridge } from '@react-three/drei';
import { TreatmentContext } from 'providers/treatmentProvider';
import LoadingSpinner from 'components/UI/LoadingSpinner/LoadingSpinner'
const Busto = React.lazy(() => import('../Busto/Busto'));

const Head = () => {
    const ContextBridge = useContextBridge(TreatmentContext);
    const [store,setStore] = useContext(TreatmentContext);
    return (
        <>    
            {store.loading && <LoadingSpinner/>}

            <Canvas camera={{position: [0,0,-100], fov: 21}}
                onCreated={(canvas) => {
                    canvas.gl.antialias = true;
                    canvas.gl.alpha = true;
                    canvas.gl.autoClearColor = false,
                    canvas.gl.setClearColor(0xffffff, 0);
                }}
            >
                <ContextBridge>
                    <color attach="background" args={["white", 0]} />
                    <OrbitControls makeDefault minDistance={20} maxDistance={100} enableZoom={true} enablePan={false} target={[0,15,0]}/>
                    <ambientLight color="#eef5b3" intensity={0.6}/>
                    {/*<directionalLight position={[1,0,1]} color="#ffffff" intensity={0.2} />
                    <directionalLight position={[-1,0,1]} color="#ffffff" intensity={0.2} />
                    <directionalLight position={[1,0,-1]} color="#ffffff" intensity={0.2} />
                    <directionalLight position={[-1,0,-1]} color="#ffffff" intensity={0.2} />
                <directionalLight position={[0,0,1]} color="#ffffff" intensity={0.2} /> */}
                    <hemisphereLight intensity={0.6} />
                    <Suspense>
                        <Busto />
                    </Suspense>
                </ContextBridge>
            </Canvas>
        </>
    )
}

export default Head;