import React, {useContext, useEffect, useState} from 'react'

import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

import { AUTHENTICATED_COOKIE_NAME, USERNAME, PASSWORD } from 'assets/constants/auth'
import nicolePicture from 'assets/images/Nicole-Login.png'
import allerganLogo from 'assets/images/logo-allergan-white.svg'
import allerganFooterLogo from 'assets/images/logo-allergan-footer.png'
import InputField from 'components/UI/InputField/InputField'
import { AuthContext } from 'providers/authProvider'
import styles from './Login.module.scss'
import { paths } from 'assets/constants/navigation'
import { Cookies } from 'components/Cookies/Cookies'


const Login = () => {
    const {authData, setAuthData} = useContext(AuthContext)
    const [formData, setFormData] = useState({})
    const [errorMessage, setErrorMessage] = useState(null)

    const handleChange = (fieldName, value) => {
        setFormData({
            ...formData,
            [fieldName]: value
        })
    }

    const login = () => {
        if ((formData.username !== USERNAME) || (formData.password !== PASSWORD)) {
            setErrorMessage('Las credenciales son incorrectas')
            return
        }

        setErrorMessage(null)
        document.cookie = `${AUTHENTICATED_COOKIE_NAME}=true`
        setAuthData({ ...authData, isLoggedIn: true })
    }

    return (
        <>
            <Helmet>
                <title>Login</title>
            </Helmet>
            <main className={styles.LoginWrapper}>
                <section>
                    {/* <img src={nicolePicture} alt='Nicole' className={styles.PatientPicture} /> */}
                </section>
                <section>
                    <img src={allerganLogo} alt='Allergan Logo' className={styles.CompanyLogo} />
                    <div className={styles.LoginForm}>
                        <InputField
                            className={styles.UserField}
                            label='Usuario'
                            onChange={(value) => handleChange('username', value)}
                            onKeyUp={(e) => {
                                if (e.key === 'Enter' || e.keyCode === 13) {
                                    login()
                                }
                            }}
                        />
                        <InputField
                            className={styles.PasswordField}
                            label='Contraseña'
                            type='password'
                            onChange={(value) => handleChange('password', value)}
                            onKeyUp={(e) => {
                                if (e.key === 'Enter' || e.keyCode === 13) {
                                    login()
                                }
                            }}
                        />
                        {
                            errorMessage &&
                            <p className={styles.ErrorMessage}>
                                {errorMessage}
                            </p>
                        }
                        <button className={styles.SubmitButton} onClick={login}>
                            Entrar
                        </button>
                        <p className={styles.InfoMessage}>
                            Por favor, si no recuerda las credenciales, contacte con su comercial de Allergan Aesthetics
                        </p>
                    </div>
                    <div className={styles.Disclaimer}>
                        <p>
                        La información contenida en este sitio web está dirigida exclusivamente a profesionales sanitarios facultados para prescribir o dispensar medicamentos/productos sanitarios en España, ya que requiere una formación especializada para su correcta interpretación.
                        Si no es profesional sanitario, visite <a href="https://www.allerganaesthetics.es/">https://www.allerganaesthetics.es/</a>. Estos contenidos están sujetos a derechos de propiedad intelectual por lo que su uso o reproducción requiere la previa autorización de AbbVie. AbbVie Spain S.L.U, Avda. Burgos 91, 28050 Madrid. 913840910. Copyright © 2023
                        </p>
                    </div>
                </section>
            </main>
            <footer>
                <div className={styles.FooterRow}>
                    <div>
                        <p>ES-JUV-220030</p>
                        <p>Fecha de elaboración: Enero 2023</p>
                    </div>
                    <div className={styles.PrivacyAndTerms}>
                        <Link
                            to={paths.PRIVACY_NOTICE}
                            target='_blank'
                            rel='noreferrer'
                            className={styles.Terms}
                        >
                            <p>Aviso de privacidad</p>
                        </Link>
                        <p>&nbsp;|&nbsp;</p>
                        <a
                            href='https://www.allerganaesthetics.es/terminos-y-condiciones'
                            target='_blank'
                            rel='noreferrer'
                            className={styles.Terms}
                        >
                            <p>Términos y condiciones</p>
                        </a>
                        <p>&nbsp;|&nbsp;</p>
                            <a
                            href='#'
                            target='_self'
                            rel='noreferrer'
                            title="Overridden by OneTrust"
                            className={styles.Terms + ' ot-sdk-show-settings'}
                        >
                            <p>Configuración de cookies</p>
                        </a>
                    </div>
                </div>
                <div className={styles.FooterRow}>
                    <img src={allerganFooterLogo} alt='Allergan logo' />
                    <div>
                        <p></p>
                    </div>
                    <div>
                        <p>©AbbVie S.L.U. {new Date().getFullYear()}. Todos los derechos reservados</p>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Login
