import React, {useContext, useEffect, useState} from 'react'
import styles from './VistabelTable.module.scss'
import vistabelLogo from 'assets/images/logo-vistabel.png'
import vistabelLogo2 from 'assets/images/logo-vistabel-white.svg'
import referenceIcon from 'assets/images/reference-icon.svg'


const VistabelTable = (props) => {

    return(<div className={`${styles.ThirdColumn}`}>
    <div>
        <img className={styles.vistabelLogo} src={vistabelLogo2} alt='Logo Vistabel' />
    </div>
    <table>
        <tbody>
            <tr>
                <td>Dosis recomendadas</td>
                <td>Líneas de la Frente 20 U Glabela 20 U Patas de gallo 24 U Total: 64 U<sup>1</sup></td>
            </tr>
            <tr>
                <td>Unidades por vial // para uso cosmético</td>
                <td>50U y 100U<sup>1</sup></td>
            </tr>
            <tr>
                <td>Indicaciones cosméticas // faciales</td>
                <td>LG, PG, LF<sup>1</sup></td>
            </tr>
            <tr>
                <td>Precisión</td>
                <td>Vistabel cuenta con una dispersión limitada.<sup>2</sup>
                </td>
            </tr>
            <tr>
                <td>Duración</td>
                <td>La media de duración del efecto es de hasta 4 meses con contracción máxima en líneas glabelares³ y de hasta 6 meses en líneas de la frente tras el tratamiento en las 3 indicaciones.<sup>1</sup>
                </td>
            </tr>
            <tr>
                <td>Satisfacción del paciente</td>
                <td>La proporción de sujetos mayoritariamente satisfechos o muy satisfechos
                    fue significativamente mayor con onabotulinumtoxina 64U en líneas glabelares, líneas de la frente y patas de gallo; frente a placebo<sup>4</sup> &nbsp; (n=787)
                </td>
            </tr>
            <tr>
                <td>Perfil de seguridad</td>
                <td>No hubo acontecimientos adversos graves relacionados con el tratamiento con Vistabel® en líneas de la frente y en patas de gallo (N=1678)<sup>5</sup>
                </td>
            </tr>
            <tr>
                <td>Años en el mercado de la toxina botulínica</td>
                <td>19 años<sup>1</sup></td>
            </tr>
        </tbody>
    </table>
    <div className={styles.InfoIcon} >
        <img
            src={referenceIcon} alt='Botón de información'
            onClick={() => props.closeModal()}
        />  
    </div>
</div>)
}

export default VistabelTable