import React, {useContext, useEffect, useState} from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCirclePlus, faCircleInfo, faCircleXmark } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom'
import { paths } from 'assets/constants/navigation'
import bgOrangeToViolet from 'assets/images/bg-orange-to-violet.png'
import hylacrossLogo from 'assets/images/logo-hylacross.png'
import referenceIcon from 'assets/images/reference-icon.svg'
import juvedermIconBlue from 'assets/images/juvederm-icon-blue.png'
import juvedermIconDarkBlue from 'assets/images/juvederm-icon-darkblue.png'
import juvedermIconDarkGreen from 'assets/images/juvederm-icon-darkgreen.png'
import juvedermIconDarkViolet from 'assets/images/juvederm-icon-darkviolet.png'
import juvedermIconViolet from 'assets/images/juvederm-icon-violet.png'
import juvedermIconWhite from 'assets/images/juvederm-icon-white.png'
import juvedermVycrossLogoWhite from 'assets/images/logo-juvederm-vycross-white.png'
import juvedermLogo2 from 'assets/images/logo-juvederm-2.svg'
import juvedermLogoWhite from 'assets/images/logo-juvederm-white.png'
import juvedermLogoWhite2 from 'assets/images/logo-juvederm-white2.svg'
import vycrossLogo from 'assets/images/logo-vycross.png'
import vistabelLogo from 'assets/images/logo-vistabel.png'
import Button from 'components/UI/Button/Button'
import ChangeBtn from 'components/UI/ChangeBtn/ChangeBtn'
import JuvedermIconItem from 'components/UI/JuvedermIconItem/JuvedermIconItem'
import styles from './Menu.module.scss'
import { TreatmentContext } from 'providers/treatmentProvider'
import IconLink from 'components/UI/IconLink/IconLink'
import Modal from 'components/UI/Modal/Modal'
import harmonycaLogo from 'assets/images/logo-harmonyca.png'
import closeMenu from 'assets/images/close-menu.svg'


const Menu = (props) => {
    const { className, onCloseIconClick } = props
    const [isModalVisible, setModalVisible] = useState(false)
    const navigate = useNavigate()

    const [store,setStore] = useContext(TreatmentContext)

    const manageStore = (family) => {

        switch (family) {
            case 'volbella':
                setStore({...store, volbellaClick: !store.volbellaClick})
                break;
            
            case 'volux':
                setStore({...store, voluxClick: !store.voluxClick})
                break;
                
            case 'voluma':
                setStore({...store, volumaClick: !store.volumaClick})
                break;
            
            case 'volite':
                setStore({...store, voliteClick: !store.voliteClick})
                break;
            
            case 'volift':
                setStore({...store, voliftClick: !store.voliftClick})
                break;
            
            default:
                break;
        }
    }

    const mdCodesChange = () => {
        setStore({...store, mdCodes: !store.mdCodes});
    }

    const areasChange = () => {
        setStore({...store, areas: !store.areas});
    }

    return (
        
        <>
            <div className={`${styles.Menu}${className ? ` ${className}` : ''}`}>
                <div className={styles.CloseMenuIconWrapper}>
                    {/* <FontAwesomeIcon
                        icon={faCircleXmark}
                        className={styles.CloseMenuIcon}
                        onClick={onCloseIconClick}
                    /> */}
                    <img 
                    src={closeMenu} 
                    alt="close icon" 
                    className={styles.CloseMenuIcon} 
                    onClick={onCloseIconClick}/>

                </div>
                <div className={styles.MenuHeader}>
                    <img src={juvedermVycrossLogoWhite} alt="Logo Juvederm" className={styles.ImgOneHeader} />
                    <p>Productos</p>
                    <div>
                        <div className={styles.CheckBoxWrapper}>
                            <div className={styles.CheckBoxNames}>
                                <span className={styles.MDCodes}>
                                    Inyección
                                </span>
                                <span className={styles.Areas}>
                                    Áreas
                                </span>
                            </div>
                            <div className={styles.CheckBoxes}>
                                <input type="checkbox" className={styles.CheckboxRounded} id={styles.MDCodes} defaultChecked={store.mdCodes} onChange={mdCodesChange}/>
                                <input type="checkbox" className={styles.CheckboxRounded} id={styles.Areas} defaultChecked={store.areas} onChange={areasChange}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.MenuBody}>
                    <div className={styles.Treatments}>
                        <div onClick={() => {manageStore('volbella')}} >
                            <JuvedermIconItem 
                                className={store.volbellaClick || store.allClick ? styles.VioletFontClicked : styles.VioletFont} 
                                size='small' 
                                icon={juvedermIconViolet} 
                                title='VOLBELLA®'
                            />
                        </div>
                        <div onClick={() => {manageStore('volift')}} >
                            <JuvedermIconItem 
                                className={store.voliftClick || store.allClick ? styles.DarkVioletFontClicked : styles.DarkVioletFont} 
                                size='small' 
                                icon={juvedermIconDarkViolet} 
                                title='VOLIFT®'
                            />
                        </div>
                        <div onClick={() => {manageStore('voluma')}} >
                            <JuvedermIconItem 
                                className={store.volumaClick || store.allClick ? styles.DarkGreenFontClicked : styles.DarkGreenFont} 
                                size='small' 
                                icon={juvedermIconDarkGreen} 
                                title='VOLUMA®'
                            />
                        </div>
                        <div onClick={() => {manageStore('volux')}} >
                            <JuvedermIconItem 
                                className={store.voluxClick || store.allClick ? styles.DarkBlueFontClicked : styles.DarkBlueFont} 
                                size='small' 
                                icon={juvedermIconDarkBlue} 
                                title={<>VOLUX<sup>TM</sup></>}
                            />
                        </div>
                        <div onClick={() => {manageStore('volite')}} >
                            <JuvedermIconItem 
                                className={store.voliteClick || store.allClick ? styles.BlueFontClicked : styles.BlueFont} 
                                size='small' 
                                icon={juvedermIconBlue} 
                                title={<>VOLITE<sup>TM</sup></>}
                            />
                        </div>
                        <div onClick={() => {setStore({...store, allClick: !store.allClick, volbellaClick: !store.allClick, voliftClick: !store.allClick, voluxClick: !store.allClick, volumaClick: !store.allClick, voliteClick: !store.allClick})}} >
                            <JuvedermIconItem 
                                className={store.allClick ? styles.WhiteFontClicked : styles.WhiteFont} 
                                size='small' icon={juvedermIconWhite} 
                                title='Todos'
                            />
                        </div>
                    </div>
                    <Button
                        className={styles.CenteredButton}
                        icon={faCirclePlus}
                        onClick={() => navigate(paths.JUVEDERMVYCROSSINFO)}
                    >
                        <span>Más información</span>
                    </Button>
                </div>
                <div className={styles.MenuFooter}>
                    <div className={styles.JuvedermVycrossLogo}>
                        <img src={juvedermLogoWhite2} alt="Logo Juvederm" className={styles.ImgTwoHeader} />
                        <img src={hylacrossLogo} alt="Logo Hylacross" className={styles.HylacrossLogo} />
                    </div>
                    <Button
                        className={styles.CenteredButton}
                        icon={faCirclePlus}
                        color={'dark-magenta'}
                        onClick={() => navigate(paths.JUVEDERMHYLACROSSINFO)}
                    >
                        <span>Más información</span>
                    </Button>
                    <div className={styles.VistabelAccess}>
                        <p>Cambia a:</p>
                        <ChangeBtn
                            className={styles.ChangeBtn}
                            logo={vistabelLogo}
                            onClick={() => {
                                setStore({...store, vistabel: true})
                                navigate(paths.VISTABEL)
                            }}    
                        />
                    </div>
                    <div className={styles.ChangeTo}>
                        <p>Cambia a</p>
                        <ChangeBtn
                            className={styles.ChangeBtn}
                            logo={harmonycaLogo}
                            onClick={() => {
                                setStore({...store, harmonyca: true})
                                navigate(paths.HARMONYCA)
                            }}    
                        />
                    </div>
                    <div className={styles.InfoIcon} >
                        <img
                            src={referenceIcon} alt='Botón de información'
                            onClick={() => setModalVisible(true)}
                        />
                    </div>
                </div>
            </div>
            {
                isModalVisible &&
                <Modal onClose={() => setModalVisible(false)} className={styles.ReferencesModal}>
                    <div>
                        <p>
                        Imagen con fines ilustrativos.
                        </p>
                        <p>
                        Se recomienda consultar Ficha Técnica / Instrucciones de uso de cada producto antes de iniciar un tratamiento.
                        </p>
                        <p>
                        1. Juvéderm® VOLBELLA® DFU / 2. Juvéderm® VOLUX™ DFU / 3. Juvéderm® VOLUMA® DFU / 4. Juvéderm® VOLIFT® DFU / 5. Juvéderm® VOLITE™ DFU / 6. Dhillon B, Patel T. A Retrospective Analysis of Full-face Dermal Filler Treatments: Product Choice, Volume Use, and Treatment Locations. J Clin Aesthet Dermatol. 2020 Sep;13(9):33-40.

                        </p>
                    </div>
                </Modal>
            }
        </>
    )
}
export default Menu