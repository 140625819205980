import React, { useContext, useState } from 'react'

import juvedermIconBlue from 'assets/images/juvederm-icon-blue.png'
import juvedermIconDarkBlue from 'assets/images/juvederm-icon-darkblue.png'
import juvedermIconDarkGreen from 'assets/images/juvederm-icon-darkgreen.png'
import juvedermIconDarkViolet from 'assets/images/juvederm-icon-darkviolet.png'
import juvedermIconViolet from 'assets/images/juvederm-icon-violet.png'
import referenceIcon from 'assets/images/reference-icon.svg'
import volbellaHourglass from 'assets/images/volbella-hourglass.svg'
import voliftHourglass from 'assets/images/volift-hourglass.svg'
import voliteHourglass from 'assets/images/volite-hourglass.svg'
import volumaHourglass from 'assets/images/voluma-hourglass.svg'
import voluxHourglass from 'assets/images/volux-hourglass.svg'
import volbellaDrops from 'assets/images/volbella-drops.svg'
import voliftDrops from 'assets/images/volift-drops.svg'
import voliteDrops from 'assets/images/volite-drops.svg'
import volumaDrops from 'assets/images/voluma-drops.svg'
import voluxDrops from 'assets/images/volux-drops.svg'
import volbellaCircles from 'assets/images/volbella-circles.svg'
import voliftCircles from 'assets/images/volift-circles.svg'
import voliteCircles from 'assets/images/volite-circles.svg'
import volumaCircles from 'assets/images/voluma-circles.svg'
import voluxCircles from 'assets/images/volux-circles.svg'
import volbellaSpring from 'assets/images/volbella-spring.svg'
import voliftSpring from 'assets/images/volift-spring.svg'
import voliteSpring from 'assets/images/volite-spring.svg'
import volumaSpring from 'assets/images/voluma-spring.svg'
import voluxSpring from 'assets/images/volux-spring.svg'
import volbellaArrow from 'assets/images/volbella-arrow.svg'
import voliftArrow from 'assets/images/volift-arrow.svg'
import voliteArrow from 'assets/images/volite-arrow.svg'
import volumaArrow from 'assets/images/voluma-arrow.svg'
import voluxArrow from 'assets/images/volux-arrow.svg'
import juvedermIconWhite from 'assets/images/juvederm-icon-white.png'
import JuvedermIconItem from 'components/UI/JuvedermIconItem/JuvedermIconItem'
import { TreatmentContext } from 'providers/treatmentProvider';
import Modal from 'components/UI/Modal/Modal'
import styles from './VycrossProductsTable.module.scss'


const VycrossProductsTable = (props) => {
    const { showInfoModal } = props
    const [store, setStore] = useContext(TreatmentContext)

    const getPropsForColumn = (index) => {
        const baseClassName = {
            1: styles.FirstColumn,
            2: styles.SecondColumn,
            3: styles.ThirdColumn,
            4: styles.FourthColumn,
            5: styles.FifthColumn,
        }[index]

        const property = {
            1: 'voliteClick',
            2: 'volbellaClick',
            3: 'voliftClick',
            4: 'volumaClick',
            5: 'voluxClick',
        }[index]
        
        const isVisible = store[property] || store['allClick']
        const extraClassName = isVisible ? '' : styles.Hidden

        return { className: `${baseClassName}${extraClassName ? ` ${extraClassName}` : ''}` }
    }

    return (
        <>
            <div className={styles.ComparativeTable}>
                <table>
                    <thead>
                        <tr>
                            <th>
                            </th>
                            <th {...getPropsForColumn(1)}>
                                <JuvedermIconItem
                                    className={styles.BlueFont}
                                    icon={juvedermIconBlue}
                                    title={<>Volite<sup>TM</sup></>}
                                />
                                <p>Hidratación, elasticidad y suavidad de la piel<sup>1</sup></p>
                            </th>
                            <th {...getPropsForColumn(2)}>
                                <JuvedermIconItem
                                    className={styles.VioletFont}
                                    icon={juvedermIconViolet}
                                    title={<>Volbella<sup>®</sup></>}
                                />
                                <p>Suaviza las líneas faciales y trata los labios y ojeras<sup>2</sup></p>
                            </th>
                            <th {...getPropsForColumn(3)}>
                                <JuvedermIconItem
                                    className={styles.DarkVioletFont}
                                    icon={juvedermIconDarkViolet}
                                    title={<>Volift<sup>®</sup></>}
                                />
                                <p>Reafirma y alisa arrugas y surcos profundos<sup>3</sup></p>
                            </th>
                            <th {...getPropsForColumn(4)}>
                                <JuvedermIconItem
                                    className={styles.DarkGreenFont}
                                    icon={juvedermIconDarkGreen}
                                    title={<>Voluma<sup>®</sup></>}
                                />
                                <p>Restaura el volumen del tercio medio del rostro<sup>4</sup></p>
                            </th>
                            <th {...getPropsForColumn(5)}>
                                <JuvedermIconItem
                                    className={styles.DarkBlueFont}
                                    icon={juvedermIconDarkBlue}
                                    title={<>Volux<sup>TM</sup></>}
                                />
                                <p>Restaura y crea volumen en el tercio inferior del rostro<sup>5</sup></p>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className={styles.TitleColumn}>
                                <JuvedermIconItem icon={juvedermIconViolet} title='Duración' />
                                <p>(meses)</p>
                            </td>
                            <td {...getPropsForColumn(1)}>
                                <div>
                                    <img className={styles.Hourglass} src={voliteHourglass} alt='Logo' />
                                    <span>6</span>
                                </div>
                            </td>
                            <td {...getPropsForColumn(2)}>
                                <div>
                                    <img className={styles.Hourglass} src={volbellaHourglass} alt='Logo' />
                                    <span>7</span>
                                </div>
                            </td>
                            <td {...getPropsForColumn(3)}>
                                <div>
                                    <img className={styles.Hourglass} src={voliftHourglass} alt='Logo' />
                                    <span>8</span>
                                </div>
                            </td>
                            <td {...getPropsForColumn(4)}>
                                <div>
                                    <img className={styles.Hourglass} src={volumaHourglass} alt='Logo' />
                                    <span>9</span>
                                </div>
                            </td>
                            <td {...getPropsForColumn(5)}>
                                <div>
                                    <img className={styles.Hourglass} src={voluxHourglass} alt='Logo' />
                                    <span>10</span>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className={styles.TitleColumn}>
                                <JuvedermIconItem icon={juvedermIconDarkViolet} title='Profundidad de la inyección' />
                            </td>
                            <td {...getPropsForColumn(1)}>
                                <p>Intradérmica<sup>1</sup></p>
                            </td>
                            <td {...getPropsForColumn(2)}>
                                <p>Superficial</p>
                                <p>Dermis media</p>
                                <p>Mucosa labial<sup>2</sup></p>
                            </td>
                            <td {...getPropsForColumn(3)}>
                                <p>Dermis profunda</p>
                                <p>Mucosa labial<sup>3</sup></p>
                            </td>
                            <td {...getPropsForColumn(4)}>
                                <p>Subcutánea</p>
                                <p>Dermis profunda<sup>4</sup></p>
                            </td>
                            <td {...getPropsForColumn(5)}>
                                <p>Subcutánea</p>
                                <p>Supraperióstico<sup>5</sup></p>
                            </td>
                        </tr>
                        <tr>
                            <td className={styles.TitleColumn}>
                                <JuvedermIconItem icon={juvedermIconDarkGreen} title='Concentración de AH' />
                                <p>(mg/ml) 0,3% Lidocaína<sup>1-5</sup></p>
                            </td>
                            <td {...getPropsForColumn(1)}>
                                <p>12</p>
                                <img className={styles.Drops} src={voliteDrops} alt={'12'}/>
                            </td>
                            <td {...getPropsForColumn(2)}>
                                <p>15</p>
                                <img className={styles.Drops} src={volbellaDrops} alt={'15'}/>
                            </td>
                            <td {...getPropsForColumn(3)}>
                                <p>17,5</p>
                                <img className={styles.Drops} src={voliftDrops} alt={'17,5'}/>
                            </td>
                            <td {...getPropsForColumn(4)}>
                                <p>20</p>
                                <img className={styles.Drops} src={volumaDrops} alt={'20'}/>
                            </td>
                            <td {...getPropsForColumn(5)}>
                                <p>25</p>
                                <img className={styles.Drops} src={voluxDrops} alt={'25'}/>
                            </td>
                        </tr>
                        <tr>
                            <td className={styles.TitleColumn}>
                                <JuvedermIconItem icon={juvedermIconDarkBlue} title='Fuerza de Extrusión' />
                                <p>a50 mm/min (N)<sup>11,12</sup></p>
                            </td>
                            <td {...getPropsForColumn(1)}>
                                <p>19,7</p>
                                <p>(aguja de 32 G 1/2&quot;)</p>
                            </td>
                            <td {...getPropsForColumn(2)}>
                                <p>13,8</p>
                                <p>(aguja de 32 G 1/2&quot;)</p>
                            </td>
                            <td {...getPropsForColumn(3)}>
                                <p>18,6</p>
                                <p>(aguja de 30 G 1/2&quot;)</p>
                            </td>
                            <td {...getPropsForColumn(4)}>
                                <p>15,8</p>
                                <p>(aguja de 27 G 1/2&quot;)</p>
                            </td>
                            <td {...getPropsForColumn(5)}>
                                <p>16,5</p>
                                <p>(aguja de 27 G 1/2&quot;)</p>
                            </td>
                        </tr>
                        <tr>
                            <td className={styles.TitleColumn}>
                                <JuvedermIconItem icon={juvedermIconBlue} title='Cohesividad' />
                                <p>(Gramos-fuerza[gf])<sup>13</sup></p>
                            </td>
                            <td {...getPropsForColumn(1)}>
                                <p>12</p>
                                <img className={styles.Circles} src={voliteCircles} alt={'12'}/>
                            </td>
                            <td {...getPropsForColumn(2)}>
                                <p>19</p>
                                <img className={styles.Circles} src={volbellaCircles} alt={'19'}/>
                            </td>
                            <td {...getPropsForColumn(3)}>
                                <p>30</p>
                                <img className={styles.Circles} src={voliftCircles} alt={'30'}/>
                            </td>
                            <td {...getPropsForColumn(4)}>
                                <p>40</p>
                                <img className={styles.Circles} src={volumaCircles} alt={'40'}/>
                            </td>
                            <td {...getPropsForColumn(5)}>
                                <p>93</p>
                                <img className={styles.Circles} src={voluxCircles} alt={'93'}/>
                            </td>
                        </tr>
                        <tr>
                            <td className={styles.TitleColumn}>
                                <JuvedermIconItem icon={juvedermIconViolet} title='Elasticidad' />
                                <p>(G&apos; a 5Hz, pascal [Pa])<sup>13</sup></p>
                            </td>
                            <td {...getPropsForColumn(1)}>
                                <p>166</p>
                                <img className={styles.Spring} src={voliteSpring} alt={'166'}/>
                            </td>
                            <td {...getPropsForColumn(2)}>
                                <p>271</p>
                                <img className={styles.Spring} src={volbellaSpring} alt={'271'}/>
                            </td>
                            <td {...getPropsForColumn(3)}>
                                <p>340</p>
                                <img className={styles.Spring} src={voliftSpring} alt={'340'}/>
                            </td>
                            <td {...getPropsForColumn(4)}>
                                <p>398</p>
                                <img className={styles.Spring} src={volumaSpring} alt={'398'}/>
                            </td>
                            <td {...getPropsForColumn(5)}>
                                <p>665</p>
                                <img className={styles.Spring} src={voluxSpring} alt={'665'}/>
                            </td>
                        </tr>
                        <tr>
                            <td className={styles.TitleColumn}>
                                <JuvedermIconItem icon={juvedermIconWhite} title={<>Capacidad de elevación<sup>13</sup></>}/>
                            </td>
                            <td {...getPropsForColumn(1)}>
                                <img className={styles.Arrow} src={voliteArrow} alt={'2'}/>
                            </td>
                            <td {...getPropsForColumn(2)}>
                                <img className={styles.Arrow} src={volumaArrow} alt={'4'}/>
                            </td>
                            <td {...getPropsForColumn(3)}>
                                <img className={styles.Arrow} src={voliftArrow} alt={'6'}/>
                            </td>
                            <td {...getPropsForColumn(4)}>
                                <img className={styles.Arrow} src={volumaArrow} alt={'8'}/>
                            </td>
                            <td {...getPropsForColumn(5)}>
                                <img className={styles.Arrow} src={voluxArrow} alt={'10'}/>
                            </td>
                        </tr>
                    </tbody>
                    <div className={styles.InfoIcon} >
                        <img
                            src={referenceIcon} alt='Botón de información'
                            onClick={showInfoModal}
                        />
                    </div>
                </table>
            </div>
        </>
    )
}

export default VycrossProductsTable