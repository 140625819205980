import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import styles from './Button.module.scss'


const Button = (props) => {
    const { children, className, color, icon, onClick } = props

    const colorMap = {
        'magenta': styles.Magenta,
        'dark-magenta': styles.DarkMagenta,
        'aubergine': styles.Aubergine,
        'lollipop': styles.Lollipop,
    }

    return (
        <button
            className={`${styles.Button} ${colorMap[color] || styles.Magenta}${className ? ` ${className}` : ''}`}
            onClick={onClick}
        >
            {
                icon && 
                <FontAwesomeIcon className={styles.Icon} icon={icon} />
            }
            {children}
        </button>
    )
}

export default Button
