import React from 'react'

import styles from './MainContent.module.scss'

const MainContent = (props) => {
    const { children, className } = props

    return (
        <main className={`${styles.MainContent}${className ? ` ${className}` : ''}`}>
            {children}
        </main>
    )
}

export default MainContent