const paths = {
    HOME: '/',
    HARMONYCA: '/harmonyca',
    HARMONYCAINFO: '/harmonycainfo',
    VISTABEL: '/vistabel',
    VISTABELINFO: '/vistabelinfo',
    JUVEDERMVYCROSSINFO: '/juvedermvycross/info',
    JUVEDERMHYLACROSSINFO: '/juvedermhylacross/info',
    LOGIN: '/login',
    PRIVACY_NOTICE: '/privacy-notice',
    TESTS: '/tests',
}

export {
    paths,
}