import React, { useContext } from 'react'
import { useRoutes } from 'react-router-dom'

import { AuthContext } from 'providers/authProvider'
import routes from 'routes'
import './App.scss'

const App = () => {
    const {authData, setAuthData, isCheckingStatus} = useContext(AuthContext)
    const appRoutes = useRoutes(routes())

    return isCheckingStatus === false ? appRoutes : null
}

export default App
