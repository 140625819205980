import React, {useContext, useEffect, useState} from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCirclePlus, faCircleInfo } from '@fortawesome/free-solid-svg-icons'
import { Helmet } from 'react-helmet-async'
import { useNavigate } from 'react-router-dom'

import { paths } from 'assets/constants/navigation'
import technicalSheet from 'assets/docs/FichaTecnica2023.pdf'
import whySheet from 'assets/docs/PorqueVistabel2023.pdf'
import inyectionSheet from 'assets/docs/AMI_DipticoVistabelconFT.pdf'
import referenceIcon from 'assets/images/reference-icon.svg'
import vistabelLogo from 'assets/images/logo-vistabel.png'
import vistabelLogo2 from 'assets/images/logo-vistabel-white.svg'
import juvedermLogo from 'assets/images/logo-juvederm.png'
import Button from 'components/UI/Button/Button'
import ChangeBtn from 'components/UI/ChangeBtn/ChangeBtn'
import Header from 'components/UI/Header/Header'
import MainContent from 'components/UI/MainContent/MainContent'
import Head from 'components/UI/Head/Head'
import Footer from 'components/Footer/Footervistabel'
import styles from './Vistabel.module.scss'
import { TreatmentContext } from 'providers/treatmentProvider'
import Modal from 'components/UI/Modal/Modal'
import harmonycaLogo from 'assets/images/logo-harmonyca.png'
import plus from 'assets/images/plus-btn.svg'
import VistabelTable from './VistabelTable.jsx'


const Vistabel = () => {
    const [isModalVisible, setModalVisible] = useState(false)
    const [isTableModalVisible, setTableModalVisible] = useState(false)
    const navigate = useNavigate()
    const [store,setStore] = useContext(TreatmentContext)
    const [isMenuMobileVisible, setMenuMobileVisible] = useState(false)

    const [isModal2Visible, setModa2lVisible] = useState(false)

    const openPDF = (pdf) => {
        const tempAnchor = document.createElement('a')
        tempAnchor.setAttribute('rel', 'noopener')
        tempAnchor.setAttribute('target', '_blank')
        tempAnchor.setAttribute('href', pdf)
        tempAnchor.style.display = 'none'
        document.body.appendChild(tempAnchor)
        tempAnchor.click()
        document.body.removeChild(tempAnchor)
    }
    
    useEffect(() => {
        setStore({...store, vistabel: true, harmonyca: false})
    },[])

    const toogleMenu = () =>{
        setModa2lVisible(true)
    }

    const closeModal = () =>{
        setTableModalVisible(true)
        setModa2lVisible(false)
    }



    return (
        <>
            <Helmet>
                <title>Vistabel</title>
            </Helmet>
            <Header
                className={styles.Header}
                logo={vistabelLogo}
                logoMobile={vistabelLogo}
                color='white'
                title='EL DETALLE NO LO ES TODO, ES LO UNICO'
                menuIcon onMenuIconClick={() => setMenuMobileVisible(!isMenuMobileVisible)}
            />
            <MainContent className={styles.MainContent}>
                <div className={`${styles.FirstColumn} ${isMenuMobileVisible ? `${styles.VisibleMobile}` : ''}`}>
                    <div className={styles.FirstSection}>
                        <h1>Tercio superior con Vistabel<sup>®</sup><sup>1</sup></h1>
                        <p>Líneas glabelares (LG)</p>
                        <p>Líneas del canto lateral (LCL) / patas de gallo (PG)</p>
                        <p>Líneas de la frente (LF)</p>
                    </div>
                    <div className={styles.BtnSection}>
                        <Button
                            className={styles.CenteredButton}
                            color='aubergine'
                            icon={faCirclePlus}
                            onClick={() => navigate(paths.VISTABELINFO)}
                        >
                            <span>LAS 3P’s DE VISTABEL</span>
                        </Button>
                        <Button
                            className={styles.CenteredButton}
                            color='lollipop'
                            icon={faCirclePlus}
                            onClick={() => openPDF(technicalSheet)}
                        >
                            <span>Ficha técnica</span>
                        </Button>
                        <Button
                            className={styles.CenteredButton}
                            color='aubergine'
                            icon={faCirclePlus}
                            onClick={() => openPDF(whySheet)}
                        >
                            <span>¿Por qué elegir Vistabel?</span>
                        </Button>
                        
                    </div>
                    <div className={styles.ChangeTo}>
                        <p>Cambia a</p>
                        <ChangeBtn
                            logo={juvedermLogo}
                            onClick={() => {
                                setStore({...store, vistabel: false})
                                navigate(paths.HOME)
                            }}    
                        />
                    </div>

                    <div className={styles.ChangeTo}>
                        <p>Cambia a</p>
                        <ChangeBtn
                            logo={harmonycaLogo}
                            onClick={() => {
                                setStore({...store, vistabel: false})
                                navigate(paths.HARMONYCA)
                            }}    
                        />
                    </div>

                    <div className={styles.InfoIcon} >
                        <img
                            src={referenceIcon} alt='Botón de información'
                            onClick={() => setModalVisible(true)}
                        />  
                    </div>
                </div>
                <div className={styles.SecondColumn}>
                    <div className={styles.floatMenu}>
                        <img className={styles.plus} src={plus} alt='plus' onClick={() => toogleMenu()}/>
                    </div>
                    <Head/>
                </div>
                <div className={styles.ThirdColumn}>
                    <div>
                        <img className={styles.vistabelLogo} src={vistabelLogo2} alt='Logo Vistabel' />
                    </div>
                    <table>
                        <tbody>
                            <tr>
                                <td>Dosis recomendadas</td>
                                <td>Líneas de la Frente 20 U Glabela 20 U Patas de gallo 24 U Total: 64 U<sup>1</sup></td>
                            </tr>
                            <tr>
                                <td>Unidades por vial // para uso cosmético</td>
                                <td>50U y 100U<sup>1</sup></td>
                            </tr>
                            <tr>
                                <td>Indicaciones cosméticas // faciales</td>
                                <td>LG, PG, LF<sup>1</sup></td>
                            </tr>
                            <tr>
                                <td>Precisión</td>
                                <td>Vistabel cuenta con una dispersión limitada.<sup>2</sup>
                                </td>
                            </tr>
                            <tr>
                                <td>Duración</td>
                                <td>La media de duración del efecto es de hasta 4 meses con contracción máxima en líneas glabelares³ y de hasta 6 meses en líneas de la frente tras el tratamiento en las 3 indicaciones.<sup>1</sup>
                                </td>
                            </tr>
                            <tr>
                                <td>Satisfacción del paciente</td>
                                <td>La proporción de sujetos mayoritariamente satisfechos o muy satisfechos
                                    fue significativamente mayor con onabotulinumtoxina 64U en líneas glabelares, líneas de la frente y patas de gallo; frente a placebo<sup>4</sup> &nbsp; (n=787)
                                </td>
                            </tr>
                            <tr>
                                <td>Perfil de seguridad</td>
                                <td>No hubo acontecimientos adversos graves relacionados con el tratamiento con Vistabel® en líneas de la frente y en patas de gallo (N=1678)<sup>5</sup>
                                </td>
                            </tr>
                            <tr>
                                <td>Años en el mercado de la toxina botulínica</td>
                                <td>19 años<sup>1</sup></td>
                            </tr>
                        </tbody>
                    </table>
                    <div className={styles.InfoIcon} >
                        <img
                            src={referenceIcon} alt='Botón de información'
                            onClick={() => setTableModalVisible(true)}
                        />  
                    </div>
                </div>
            </MainContent>
            <Footer color={'grey'} logo={vistabelLogo2}/>
            {
                isModalVisible &&
                <Modal onClose={() => setModalVisible(false)} className={styles.ReferencesModal}>
                    <div>
                        <p>
                            Se recomienda consultar la Ficha Técnica/Instrucciones de uso de cada producto 
                            antes de iniciar un tratamiento.
                        </p>
                        <p>
                            1.  Ficha Técnica de Vistabel 4 Unidades Allergan / 0,1ml polvo
                             para solución inyectable (toxina botulínica tipo A) disponible.
                        </p>
                    </div>
                </Modal>
            }
            {
                isTableModalVisible &&
                <Modal onClose={() => setTableModalVisible(false)} className={styles.ReferencesModal}>
                    <div>
                        <p>
                        1. Ficha Técnica de Vistabel 4 Unidades Allergan / 0,1ml polvo para solución inyectable (toxina botulínica tipo A) disponible. 2. Kerscher M, Roll S, Becker A, Wigger-Alberti W. Comparison of the spread of three botulinum toxin type A preparations. Arch Dermatol Res. 2012 Mar;304(2):155-61. 3. De Boulle K, Werschler WP, Gold MH, Bruce S, Sattler G, Ogilvie P, Street J, Larsen KE, Yushmanova I, Lei X, Lee E, Vitarella D, Mao C. Phase 3 Study of OnabotulinumtoxinA Distributed Between Frontalis, Glabellar Complex, and Lateral Canthal Areas for Treatment of Upper Facial Lines. Dermatol Surg. 2018 Nov;44(11):1437-1448. 4.  Rivkin AZ, Ogilvie P, Dayan S, Yoelin SG, Weichman BM, Garcia JK. OnabotulinumtoxinA for Simultaneous Treatment of Upper Facial Lines: Subject-Reported Satisfaction and Impact From a Phase 3 Study. Dermatol Surg. 2020 Jan;46(1):50-60. 5. Brin MF, Boodhoo TI, Pogoda JM, James LM, Demos G, Terashima Y, Gu J, Eadie N, Bowen BL. Safety and tolerability of onabotulinumtoxinA in the treatment of facial lines: a meta-analysis of individual patient data from global clinical registration studies in 1678 participants. J Am Acad Dermatol. 2009 Dec;61(6):961-70.e1-11.


                        </p>
                    </div>
                </Modal>
            }
            {
                isModal2Visible &&
                <Modal onClose={() => setModa2lVisible(false)}>
                    <VistabelTable closeModal={closeModal}></VistabelTable>
                </Modal>
                
            }
        </>
    )
}

export default Vistabel
