import React, { createContext, useEffect, useState } from 'react'

import { AUTHENTICATED_COOKIE_NAME } from 'assets/constants/auth'
import { getCookie } from 'assets/utils/common'

const initialState = {
    isLoggedIn: null
}

export const AuthContext = createContext()

const AuthProvider = ({ children }) => {
    const [authData, setAuthData] = useState(initialState)
    const [isCheckingStatus, setCheckingStatus] = useState(true)

    const { isLoggedIn } = authData

    const isCookieDefined = () => !!getCookie(AUTHENTICATED_COOKIE_NAME)

    useEffect(() => {
        setAuthData({ ...authData, isLoggedIn: isCookieDefined()})
    }, [])

    useEffect(() => {
        if (isLoggedIn !== null) {
            setCheckingStatus(false)
        }
    }, [isLoggedIn])

    return (
        <AuthContext.Provider value={{ authData, setAuthData, isCheckingStatus }}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthProvider