import React, { useEffect, useState } from 'react'

import { v4 as uuidv4 } from 'uuid'

import styles from './InputField.module.scss'


const InputField = (props) => {
    const { className, label, onChange, type = 'text', ...rest } = props

    const [isFocused, setFocused] = useState(false)
    const [fieldValue, setFieldValue] = useState('')

    const handleChange = (event, result) => {
        const { name, value } = result || event.target
        setFieldValue(value)
        onChange(value)
    }

    const fieldId = uuidv4()

    return (
        <label
            className={`${styles.InputField}${isFocused ? ` ${styles.Focused}` : ''}${className ? ` ${className}` : ''}`}
            htmlFor={fieldId}
        >
            <span className={styles.LabelText}>
                {label}
            </span>
            <input
                {...rest}
                id={fieldId}
                name={fieldId}
                type={type === 'password' ?  type : 'text'}
                onFocus={() => !fieldValue && setFocused(true)}
                onBlur={() => !fieldValue && setFocused(false)}
                onChange={handleChange}
                className={type === 'password' ? styles.Password : undefined}
            />
        </label>
    )
}

export default InputField