import React from 'react'

import styles from './ChangeBtn.module.scss'


const ChangeBtn = (props) => {
    const { className, logo, onClick } = props

    return (
        <div
            className={`${styles.ChangeBtn}${className ? ` ${className}` : ''}`}
            onClick={onClick}
        >
            {
                logo && 
                <img className={styles.Logo} src={logo} alt='Logo' />
            }
        </div>
    )
}

export default ChangeBtn