import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import logoAllerganBlack from 'assets/images/logo-allergan-black.svg'
import logoAllerganWhite from 'assets/images/logo-allergan-white.svg'
import harmonycaLine from 'assets/images/harmonyca-line.png'

import styles from './Header.module.scss'

const Header = (props) => {
    const { className, logo, color, inverted, title, menuIcon, onMenuIconClick, logoMobile } = props

    const colorMap = {
        'white': styles.WhiteBg,
        'pink': styles.PinkBg,
        'grape': styles.GrapeBg,
    }

    return (
        <header className={`${styles.Header} ${colorMap[color] || styles.PinkBg}${className ? ` ${className}` : ''}`}>
            {
                menuIcon &&
                <FontAwesomeIcon icon={faBars} onClick={onMenuIconClick} size='2x' className={styles.MenuIcon}/>
            }
            {
                logo
                    ? <img className={styles.Logo} src={logo} alt='Logo' />
                    : <div />
            }
            <div className={styles.LogoMobile}>
                <img
                    
                    src={logoMobile}
                    alt="Logo Allergan"
                />
            </div>
            <div className={styles.TitleHarmonyca}>
                {
                    title
                        ? <><p>HArmonyCa<sup>TM</sup> ha sido diseñado para uso subdérmico y dérmico profundo.</p>
                        <img className={styles.harmonycaLine} src={harmonycaLine} alt='Logo' />
                        </>
                        : <p />
                }
            </div>
            <div className={styles.logo2H}>
                <img
                    src={inverted ? logoAllerganWhite : logoAllerganBlack}
                    alt="Logo Allergan"
                />
            </div>
        </header>
    )
}

export default Header