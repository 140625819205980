import React, { useContext } from 'react'

import ultra2Hourglass from 'assets/images/ultra2-hourglass.svg'
import ultra3Hourglass from 'assets/images/ultra3-hourglass.svg'
import ultraSmileHourglass from 'assets/images/ultrasmile-hourglass.svg'
import ultra4Hourglass from 'assets/images/ultra4-hourglass.svg'
import ultra2Drops from 'assets/images/ultra2-drops.svg'
import ultra3Drops from 'assets/images/ultra3-drops.svg'
import ultraSmileDrops from 'assets/images/ultrasmile-drops.svg'
import ultra4Drops from 'assets/images/ultra4-drops.svg'
import ultra2Circles from 'assets/images/ultra2-circles.svg'
import ultra3Circles from 'assets/images/ultra3-circles.svg'
import ultraSmileCircles from 'assets/images/ultrasmile-circles.svg'
import ultra4Circles from 'assets/images/ultra4-circles.svg'
import ultra2Spring from 'assets/images/ultra2-spring.svg'
import ultra3Spring from 'assets/images/ultra3-spring.svg'
import ultraSmileSpring from 'assets/images/ultrasmile-spring.svg'
import ultra4Spring from 'assets/images/ultra4-spring.svg'
import ultra2Face from 'assets/images/ultra2-face.svg'
import ultra3Face from 'assets/images/ultra3-face.svg'
import ultraSmileFace from 'assets/images/ultrasmile-face.svg'
import ultra4Face from 'assets/images/ultra4-face.svg'
import juvedermIconBlue from 'assets/images/juvederm-icon-blue.png'
import juvedermIconDarkBlue from 'assets/images/juvederm-icon-darkblue.png'
import juvedermIconDarkGreen from 'assets/images/juvederm-icon-darkgreen.png'
import juvedermIconDarkViolet from 'assets/images/juvederm-icon-darkviolet.png'
import juvedermIconViolet from 'assets/images/juvederm-icon-violet.png'
import juvedermIconWhite from 'assets/images/juvederm-icon-white.png'
import JuvedermIconItem from 'components/UI/JuvedermIconItem/JuvedermIconItem'
import styles from './Tests.module.scss'


const Tests = () => {

    return (
        <div className={styles.ComparativeTable}>
            <table>
                <thead>
                    <tr>
                        <th></th>
                        <th>
                            <p>Ultra 2</p>
                        </th>
                        <th>
                            <p>Ultra 3</p>
                            <p>Líneas periorales y voluminización de labios<sup>2</sup></p>
                        </th>
                        <th>
                            <p>Ultra Smile</p>
                            <p>Líneas periorales y voluminización de labio<sup>3</sup></p>
                        </th>
                        <th>
                            <p>Ultra 4</p>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className={styles.TitleColumn}>
                            <JuvedermIconItem icon={juvedermIconViolet} title='Duración' />
                            <p>(meses)</p>
                        </td>
                        <td>
                            <img className={styles.Hourglass} src={ultra2Hourglass} alt='9' />
                        </td>
                        <td>
                            <img className={styles.Hourglass} src={ultra3Hourglass} alt='12' />
                        </td>
                        <td>
                            <img className={styles.Hourglass} src={ultraSmileHourglass} alt='12' />
                        </td>
                        <td>
                            <img className={styles.Hourglass} src={ultra4Hourglass} alt='9' />
                        </td>
                    </tr>
                    <tr>
                        <td className={styles.TitleColumn}>
                            <JuvedermIconItem icon={juvedermIconDarkViolet} title='Profundidad de la inyección' />
                        </td>
                        <td>
                            <p>Dermis media</p>
                        </td>
                        <td>
                            <p>Dermis media y/o profunda</p>
                        </td>
                        <td>
                            <p>Dermis media y/o profunda</p>
                        </td>
                        <td>
                            <p>Dermis media</p>
                        </td>
                    </tr>
                    <tr>
                        <td className={styles.TitleColumn}>
                            <JuvedermIconItem icon={juvedermIconDarkGreen} title='Concentración de AH' />
                            <p>(mg/ml) 0,3% Lidocaína<sup>1-4</sup></p>
                        </td>
                        <td>
                            <p>24</p>
                            <img className={styles.Drops} src={ultra2Drops} alt={'24'}/>
                        </td>
                        <td>
                            <p>24</p>
                            <img className={styles.Drops} src={ultra3Drops} alt={'24'}/>
                        </td>
                        <td>
                            <p>24</p>
                            <img className={styles.Drops} src={ultraSmileDrops} alt={'24'}/>
                        </td>
                        <td>
                            <p>24</p>
                            <img className={styles.Drops} src={ultra4Drops} alt={'24'}/>
                        </td>
                    </tr>
                    <tr>
                        <td className={styles.TitleColumn}>
                            <JuvedermIconItem icon={juvedermIconDarkBlue} title='Fuerza de Extrusión' />
                            <p>a50 mm/min (N)<sup>5</sup></p>
                        </td>
                        <td>
                            <p>25,5</p>
                            <p>(aguja de 30 G 1/2&quot;)</p>
                        </td>
                        <td>
                            <p>15,1</p>
                            <p>(aguja de 27 G 1/2&quot;)</p>
                        </td>
                        <td>
                            <p>31,6</p>
                            <p>(aguja de 30 G 1/2&quot;)</p>
                        </td>
                        <td>
                            <p>27,7</p>
                            <p>(aguja de 27 G 1/2&quot;)</p>
                        </td>
                    </tr>
                    <tr>
                        <td className={styles.TitleColumn}>
                            <JuvedermIconItem icon={juvedermIconBlue} title='Cohesividad' />
                            <p>(Gramos-fuerza[gf])<sup>6</sup></p>
                        </td>
                        <td>
                            <p>95</p>
                            <img className={styles.Circles} src={ultra2Circles} alt={'95'}/>
                        </td>
                        <td>
                            <p>104</p>
                            <img className={styles.Circles} src={ultra3Circles} alt={'104'}/>
                        </td>
                        <td>
                            <p>104</p>
                            <img className={styles.Circles} src={ultraSmileCircles} alt={'104'}/>
                        </td>
                        <td>
                            <p>105</p>
                            <img className={styles.Circles} src={ultra4Circles} alt={'105'}/>
                        </td>
                    </tr>
                    <tr>
                        <td className={styles.TitleColumn}>
                            <JuvedermIconItem icon={juvedermIconViolet} title='Elasticidad' />
                            <p>(G&apos;© a 5Hz, pascal [Pa])<sup>7</sup></p>
                        </td>
                        <td>
                            <p>188</p>
                            <img className={styles.Spring} src={ultra2Spring} alt={'188'}/>
                        </td>
                        <td>
                            <p>238</p>
                            <img className={styles.Spring} src={ultra3Spring} alt={'238'}/>
                        </td>
                        <td>
                            <p>238</p>
                            <img className={styles.Spring} src={ultraSmileSpring} alt={'238'}/>
                        </td>
                        <td>
                            <p>164</p>
                            <img className={styles.Spring} src={ultra4Spring} alt={'164'}/>
                        </td>
                    </tr>
                    <tr>
                        <td className={styles.TitleColumn}>
                            <JuvedermIconItem icon={juvedermIconWhite} title='Área recomendada del rostro'/>
                        </td>
                        <td>
                            <img className={styles.Face} src={ultra2Face} alt={'rostro'}/>
                        </td>
                        <td>
                            <img className={styles.Face} src={ultra3Face} alt={'rostro'}/>
                        </td>
                        <td>
                            <img className={styles.Face} src={ultraSmileFace} alt={'rostro'}/>
                        </td>
                        <td>
                            <img className={styles.Face} src={ultra4Face} alt={'rostro'}/>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default Tests