import React, { useContext } from 'react'

import { Navigate, Outlet } from 'react-router-dom'

import { paths } from 'assets/constants/navigation'
import { AuthContext } from 'providers/authProvider'
import {
    Home,
    JuvedermHylacrossInfo,
    JuvedermVycrossInfo,
    Login,
    PrivacyNotice,
    Vistabel,
    VistabelInfo,
    Harmonyca,
    HarmonycaInfo,
    Tests
} from 'pages'


const routes = () => {
    const {authData, setAuthData} = useContext(AuthContext)
    const { isLoggedIn } = authData

    return [
        {
            path: paths.HOME,
            element: isLoggedIn ? <Outlet /> : <Navigate to={paths.LOGIN} />,
            children: [
                { path: paths.HOME, element: <Home /> },
                { path: paths.VISTABEL, element: <Vistabel /> },
                { path: paths.VISTABELINFO, element: <VistabelInfo /> },
                { path: paths.HARMONYCA, element: <Harmonyca /> },
                { path: paths.HARMONYCAINFO, element: <HarmonycaInfo /> },
                { path: paths.TESTS, element: <Tests /> },
                { path: paths.JUVEDERMVYCROSSINFO, element: <JuvedermVycrossInfo /> },
                { path: paths.JUVEDERMHYLACROSSINFO, element: <JuvedermHylacrossInfo /> },
            ]
        },
        {
            path: paths.LOGIN,
            element: !isLoggedIn ? <Login /> : <Navigate to={paths.HOME} />
        },
        {
            path: paths.PRIVACY_NOTICE,
            element: <PrivacyNotice />
        },
        { path: '*', element: <Navigate to={paths.HOME} /> },
    ]
}

export default routes